import React, { useState, useRef, useEffect } from 'react';
import OptionsMenu from './OptionMenu';
import NestedTable from './NestedTableV3';
import { formatNumber } from '../../helpers/common';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import styles from './NestedTable.module.css';
import { sanitiseParams } from '../../helpers/modifyJson';

const TableRow = ({
  rowId,
  row,
  dataConfig,
  expandedData,
  usedOptions,
  onOptionClick,
  onCloseNested,
  level,
  currentCategory,
  dateFilter,
  usedPayloadBreakdown,
  categoryLabel,
  currentMonthSelected,
  currentYearSelected,
  updateDataToBeExport,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [filteredCategory, setFilteredCategory] = useState();
  const [menuStyle, setMenuStyle] = useState({ position: { top: 0, left: 0 } });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItemMenu, setSelectedItemMenu] = useState(null);

  const menuRef = useRef(null);

  useEffect(() => {
    getRowItemName();
  }, []);

  const renderCell = (config, rowData) => {
    if (config.render) {
      return config.render(rowData);
    }

    if (typeof rowData[config.dataIndex] === 'number') {
      return formatNumber(rowData[config.dataIndex]);
    }

    if (!rowData[config.dataIndex]) {
      return <span style={{ color: 'white' }}>No Record</span>;
    }

    return rowData[config.dataIndex];
  };

  const handleClickMenu = (event) => {
    setIsMenuOpen(event.currentTarget);
  };

  const handleViewMore = () => {
    if (level === 0) {
      handleLevelOneViewMoreNavigate();
      return;
    }
    const body = {
      ...(({ ...rest }) => rest)(usedPayloadBreakdown),
      [filteredCategory]: row.itemName,
      date: dateFilter,
    };

    const url = objectToQueryParams('/PMS/MultiLedgerRecords', body);
    window.open(url, '_blank');
  };

  const handleLevelOneViewMoreNavigate = () => {
    if (currentCategory === 'byClient') {
      window.open(
        `/PMS/LedgerRecords?category=${currentCategory}&ClientCode=${row.clientCode}&date=${dateFilter}`,
        '_blank'
      );
    }

    if (currentCategory === 'byCountry') {
      window.open(
        `/PMS/LedgerRecords?category=${currentCategory}&Region=${row.region}&date=${dateFilter}`,
        '_blank'
      );
    }
    if (currentCategory === 'byCountryBank') {
      window.open(
        `/PMS/LedgerRecords?category=${currentCategory}&Region=${row.region}&BankCode=${row.bankCode}&date=${dateFilter}`,
        '_blank'
      );
    }
    if (currentCategory === 'byCurrency') {
      window.open(
        `/PMS/LedgerRecords?category=${currentCategory}&Currency=${row.currency}&date=${dateFilter}`,
        '_blank'
      );
    }
    if (currentCategory === 'byName') {
      window.open(
        `/PMS/LedgerRecords?category=${currentCategory}&NameCode=${row.nameCode}&date=${dateFilter}`,
        '_blank'
      );
    }
    if (currentCategory === 'byType') {
      window.open(
        `/PMS/LedgerRecords?category=${currentCategory}&Type=${row.type}&date=${dateFilter}`,
        '_blank'
      );
    }
  };

  const getRowItemName = () => {
    if (dataConfig[0].label === 'Currency') {
      setFilteredCategory('currency');
      return 'currency';
    }
    if (dataConfig[0].label === 'Client') {
      setFilteredCategory('clientCode');
      return 'clientCode';
    }
    if (dataConfig[0].label === 'Country') {
      setFilteredCategory('region');
      return 'region';
    }
    if (dataConfig[0].label === 'Bank') {
      setFilteredCategory('bankCode');
      return 'bankCode';
    }
    if (dataConfig[0].label === 'Name') {
      setFilteredCategory('nameCode');
      return 'nameCode';
    }
    if (dataConfig[0].label === 'Account Type') {
      setFilteredCategory('type');
      return 'type';
    }
  };

  const objectToQueryParams = (baseUrl, params) => {
    const queryString = Object.entries(params)
      .filter(([key, value]) => value !== undefined && value !== null) // Omit undefined values
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join('&');
    return `${baseUrl}?${queryString}`;
  };

  return (
    <>
      <tr className={`table-row level-${level}`}>
        {dataConfig.map((config) => (
          <td key={config.dataIndex}>{renderCell(config, row)}</td>
        ))}
        <td>
          <div className="actions-cell" style={{ position: 'relative' }}>
            {!expandedData && (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <ControlPointIcon
                  onClick={(event) => handleClickMenu(event)}
                  style={{ width: '50px', cursor: 'pointer' }}
                />
                <button
                  className={styles.actionButton3}
                  onClick={handleViewMore}
                >
                  View More
                </button>
              </div>
            )}
            {expandedData && (
              <RemoveCircleOutlineIcon
                onClick={() => onCloseNested(rowId, row)}
                style={{ width: '50px', cursor: 'pointer' }}
              />
            )}

            {isMenuOpen && (
              <div
                // style={menuStyle.position}
                // style={{ ...calculateMenuPosition() }}
                // style={menuStylePosition}
                // style={
                //   aboveStlye
                //     ? { bottom: '100%', position: 'absolute', top: 'auto' }
                //     : { left: 0, position: 'absolute', right: 0 }
                // }
                // style={{
                //   position: 'fixed',
                //   // left: '0',
                //   right: '20',
                //   top: 70,
                //   bottom: 'auto',
                //   zIndex: 1000,
                // }}
                // style={{
                //   position: 'absolute',
                // }}
                onClick={(e) => {
                  const rect = e.target.getBoundingClientRect();
                  setMenuStyle({
                    position: {
                      top: rect.top + window.scrollY, // Add scroll offset for correct positioning
                      left: rect.left + window.scrollX, // Add scroll offset for correct positioning
                    },
                  });
                  setIsMenuOpen(null);
                }}
                ref={menuRef}
                // className={showAbove ? styles.aboveMenu : styles.belowMenu}
                className={styles.belowMenu}
              >
                <OptionsMenu
                  rowId={rowId}
                  rowData={row}
                  usedOptions={usedOptions}
                  level={level}
                  onOptionClick={(option) => {
                    onOptionClick(rowId, option, row, dataConfig[0].label);
                    setIsMenuOpen(null);
                  }}
                  // position={menuStylePosition}
                  currentCategory={currentCategory}
                  anchorEl={isMenuOpen}
                  open={Boolean(isMenuOpen)}
                  onClose={() => setIsMenuOpen(null)}
                />
              </div>
            )}
          </div>
        </td>
      </tr>
      {expandedData && (
        <tr>
          <td colSpan={dataConfig.length + 1}>
            <div className="nested-content">
              <NestedTable
                dataConfig={[
                  { dataIndex: 'itemName', label: categoryLabel },
                  {
                    dataIndex: 'totalUsd',
                    label: `USD ${currentMonthSelected} ${currentYearSelected}`,
                  },
                ]}
                dataSource={expandedData.data}
                level={level + 1}
                currentCategory={currentCategory}
                usedOptionsBreakdown={usedOptions}
                dateFilter={dateFilter}
                usedPayloadBreakdown={usedPayloadBreakdown}
                currentMonthSelected={currentMonthSelected}
                currentYearSelected={currentYearSelected}
                updateDataToBeExport={updateDataToBeExport}
              />
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default TableRow;
