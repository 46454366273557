import { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Row, Container, Col } from 'react-bootstrap';
import PivotTableV2 from './components/PivotTableV2.jsx';
import Pagination from './components/Pagination.jsx';
import { formatDate } from '../helpers/common';
import { sanitiseParams } from '../helpers/modifyJson.js';
import CancelIcon from '@mui/icons-material/Cancel';
import styles from './components/Table.module.css';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  styled,
} from '@mui/material';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import Spinner from '../common/spinner.js';

const StyledDialog = styled(Dialog)({
  '& .MuiPaper-root': {
    backgroundColor: '#212529',
    color: '#ffffff',
  },
});

const axios = require('axios');

const postAsync = async (url, payload, header = null) => {
  const base_url = 'https://apiv2-398476.backoffice.gg/api/v1';

  try {
    var headers = { 'Content-Type': 'application/json; charset=utf-8' };
    if (header) headers = Object.assign(headers, header);
    const token = localStorage.getItem('newApiToken');

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    var resp = await axios({
      headers: headers,
      method: 'POST',
      url: `${base_url}/${url}`,
      data: sanitiseParams(payload),
    });

    return resp.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const TRANSACTION_LABELS = [
  {
    value: 'byClient',
    label: 'Client',
  },
  { value: 'byCountry', label: 'Country' },
  { value: 'byCountryBank', label: 'Bank' },
  { value: 'byCurrency', label: 'Currency' },
  { value: 'byName', label: 'Name' },
  { value: 'byType', label: 'Account Type' },
];

const renameKey = (obj, oldKey, newKey) => {
  if (obj.hasOwnProperty(oldKey)) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
  }
  return obj;
};

const byCountryBank = [
  'PBB HKG',
  'BOT TWN',
  'CTBC JPN',
  'AFB MUS',
  'MBB SGP',
  'UOB SGP',
  'KEB KOR',
  'ENBD UAE',
  'CBD UAE',
  'AMC UAE',
  'CTBC TWN',
  'CTBC HKG',
  'EIB UAE',
  'WIO UAE',
  'DIB UAE',
  'MB TWN',
  'WLB HKG',
  'CCB HKG',
  'BOC MAC',
  'UCB MNE',
  'SCB UAE',
  'SQB CHE',
  'SB TWN',
  'DB BHS',
  'BOCM HKG',
  'SMBC JPN',
];

const MultiLedgerTransactionRecords = () => {
  const [searchParams] = useSearchParams();

  const paramsObject = Object.fromEntries(searchParams.entries());

  const [responseData, setResponseData] = useState();
  const [tableDataConfig, setTableDataConfig] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [searchFilters, setSearchFilters] = useState({
    ...paramsObject,
    date: new Date(paramsObject.date).toISOString(),
    pageSize: 100,
    pageIndex: pageIndex,
    orderBy: 'date',
    sorting: 'desc',
  });
  const [clearSearchFilters, setClearSearchFilters] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [paramsBankCode, setParamsBankCode] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [regionCode, setRegionCode] = useState('');

  const debounceTimeout = useRef(null);

  const hasParams = Array.from(searchParams.keys()).length > 0;

  let splitBankCode;
  let splitRegion;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageIndex]);

  useEffect(() => {
    const init = async () => {
      if (paramsObject.bankCode) {
        splitBankCode = paramsObject?.bankCode.split(' ')[0];
        splitRegion = paramsObject?.bankCode.split(' ')[1];
        const bankRegionCode = byCountryBank.find((item) =>
          item.startsWith(paramsObject.bankCode + ' ')
        );
        if (bankRegionCode) {
          const country = bankRegionCode.split(' ')[1];
          setRegionCode(country);
        }
        setParamsBankCode(splitBankCode);
      }
      const response = await postAsync(
        'treasure/multiplebreakdown-excel-record',
        {
          ...paramsObject,
          regionCode: null,
          bankCode: splitBankCode,
          category: null,
          date: new Date(paramsObject.date).toISOString(),
          pageSize: 100,
          pageIndex: 1,
          orderBy: 'date',
          sorting: 'desc',
        }
      );

      if (response?.result) {
        setIsLoading(false);
      }

      setSearchFilters({
        ...paramsObject,
        date: new Date(paramsObject.date).toISOString(),
        pageSize: 100,
        pageIndex: 1,
        orderBy: 'date',
        sorting: 'desc',
      });
      setResponseData(response?.result);
    };

    init();
    responseDataConfig();
  }, [hasParams, clearSearchFilters]);

  // useEffect(() => {
  //   const init = async () => {
  //     const response = await postAsync('treasure/excel-record', {
  //       ...paramsObject,
  //       ...searchFilters,
  //       pageIndex: pageIndex,
  //     });
  //     setResponseData(response?.result);
  //   };

  //   init();
  //   responseDataConfig();
  // }, [pageIndex]);

  const responseDataConfig = () => {
    setTableDataConfig([
      {
        dataIndex: 'recordDate',
        label: 'Date',
      },
      { dataIndex: 'bankName', label: 'Bank Name' },
      { dataIndex: 'bankRegion', label: 'Country' },
      { dataIndex: 'bankCode', label: 'Bank Code' },
      { dataIndex: 'currency', label: 'Currency' },
      { dataIndex: 'clientCode', label: 'Client' },
      { dataIndex: 'boCode', label: 'Name' },
      { dataIndex: 'pvNo', label: 'PV No' },
      { dataIndex: 'chequeNo', label: 'Cheque No' },
      { dataIndex: 'payeeOrRemitter', label: 'Payee/Remitter' },
      { dataIndex: 'description', label: 'Description' },
      { dataIndex: 'debit', label: 'Debit' },
      { dataIndex: 'credit', label: 'Credit' },
      { dataIndex: 'balance', label: 'Balance' },
    ]);
  };

  const handlePageChange = async (newPage) => {
    setPageIndex(newPage);

    const response = await postAsync(
      'treasure/multiplebreakdown-excel-record',
      {
        ...paramsObject,
        regionCode: null,
        category: null,
        bankCode: paramsBankCode,
        ...searchFilters,
        pageIndex: newPage,
      }
    );
    if (response?.result) {
      setIsLoading(false);
    }
    setResponseData(response?.result);
  };

  const getLabelData = () => {
    if (paramsObject.category === 'byClient') {
      return paramsObject.clientCode;
    }
    if (paramsObject.category === 'byCountry') {
      return paramsObject.region;
    }
    if (paramsObject.category === 'byCountryBank') {
      return paramsObject.bankCode;
    }
    if (paramsObject.category === 'byCurrency') {
      return paramsObject.currency;
    }
    if (paramsObject.category === 'byName') {
      return paramsObject.nameCode;
    }
    if (paramsObject.category === 'byType') {
      return paramsObject.type;
    }

    return paramsObject.clientCode;
  };

  const handleSearchColumn = (searchColumns) => {
    // Clear the previous timeout if the function is called again before the timeout completes
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set a new timeout to call the API after a delay
    debounceTimeout.current = setTimeout(async () => {
      // shallow copy of the searchColumns object to prevent input reset
      let currentSearchFilters = { ...searchColumns };
      const renamedDate = renameKey(currentSearchFilters, 'recordDate', 'date');

      const body = {
        ...paramsObject,
        regionCode: null,
        bankCode: paramsBankCode,
        category: null,
        date: new Date(paramsObject.date).toISOString(),
        pageSize: 100,
        pageIndex: 1,
        orderBy: 'date',
        sorting: 'desc',
        searchFilter: sanitiseParams(renamedDate),
      };

      setSearchFilters(body);
      setPageIndex(1);
      const response = await postAsync(
        'treasure/multiplebreakdown-excel-record',
        body
      );
      setResponseData(response?.result);

      // Clear the reference after the timeout executes
      debounceTimeout.current = null;
    }, 1500); // Delay of 1.5 seconds
  };

  const handleOnAscendingSorting = async (dataIndex) => {
    let newPageIndex = searchFilters.pageIndex;
    if (searchFilters.orderBy !== dataIndex) {
      newPageIndex = 1;
      setPageIndex(1);
    }
    const response = await postAsync(
      'treasure/multiplebreakdown-excel-record',
      {
        ...searchFilters,
        pageIndex: newPageIndex,
        orderBy: dataIndex === 'recordDate' ? 'date' : dataIndex,
        sorting: 'asc',
      }
    );
    setSearchFilters({
      ...searchFilters,
      orderBy: dataIndex,
      pageIndex: newPageIndex,
      sorting: 'asc',
    });
    setResponseData(response?.result);
  };

  const handleOnDescendingSorting = async (dataIndex) => {
    let newPageIndex = searchFilters.pageIndex;
    if (searchFilters.orderBy !== dataIndex) {
      newPageIndex = 1;
      setPageIndex(1);
    }
    const response = await postAsync(
      'treasure/multiplebreakdown-excel-record',
      {
        ...searchFilters,
        pageIndex: newPageIndex,
        orderBy: dataIndex === 'recordDate' ? 'date' : dataIndex,
        sorting: 'desc',
      }
    );
    setSearchFilters({
      ...searchFilters,
      orderBy: dataIndex,
      pageIndex: newPageIndex,
      sorting: 'desc',
    });

    setResponseData(response?.result);
  };

  const handleClearFilters = () => {
    setClearSearchFilters((prev) => !prev);
  };

  const onEditRecord = (record) => {
    setFormData(record);
    setOpenEditModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await postAsync('treasure/update-excel-record', {
      recordId: formData.recordId,
      payeeOrRemitter:
        formData.payeeOrRemitter === ''
          ? 'No Record'
          : formData.payeeOrRemitter,
      description:
        formData.description === '' ? 'No Record' : formData.description,
      pvNo: formData.pvNo === '' ? 'No Record' : formData.pvNo,
      chequeNo: formData.chequeNo === '' ? 'No Record' : formData.chequeNo,
    });

    if (!response?.success) {
      return;
    }

    setOpenEditModal(false);
    setShowSuccess(true);
    const refetchData = await postAsync(
      'treasure/multiplebreakdown-excel-record',
      {
        ...paramsObject,
        regionCode: null,
        bankCode: paramsBankCode,
        category: null,
        date: new Date(paramsObject.date).toISOString(),
        pageSize: 100,
        pageIndex: 1,
        orderBy: 'date',
        sorting: 'desc',
      }
    );
    setResponseData(refetchData?.result);
  };

  const actionColumn = {
    dataIndex: 'actions',
    label: 'Action',
    render: (record) => {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            // alignItems: 'center',
            // selfAlign: 'center',
          }}
        >
          <button onClick={() => onEditRecord(record)}>Edit</button>
        </div>
      );
    },
  };

  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <Row>
            <span className="p-2 pageheader">
              <h3 className="pagetitle">Ledger Transaction Records</h3>
            </span>
          </Row>
          {/* <Row
            style={{
              marginBottom: '8px',
              justifyContent: 'space-between',
            }}
          >
            <span className="p-2 pageheader">
              <h3 className="pagetitle">
                {
                  TRANSACTION_LABELS.find(
                    (item) => item.value === paramsObject.category
                  )?.label
                }
                : {getLabelData()}
              </h3>
            </span>
          </Row> */}
          {paramsObject.clientCode && (
            <Row
              style={{
                justifyContent: 'space-between',
                marginTop: -30,
              }}
            >
              <span className="p-2 pageheader">
                <h3 className="pagetitle">
                  Client : {paramsObject.clientCode}
                </h3>
              </span>
            </Row>
          )}
          {paramsObject.bankCode && (
            <Row
              style={{
                justifyContent: 'space-between',
                marginTop: -30,
              }}
            >
              <span className="p-2 pageheader">
                <h3 className="pagetitle">
                  Bank :{' '}
                  {/* {paramsObject?.bankCode.split(' ')[1]
                    ? paramsObject?.bankCode
                    : paramsObject.bankCode +
                      ' ' +
                      (paramsObject.region
                        ? paramsObject.region
                        : responseData?.items[0]?.bankRegion || regionCode)} */}
                  {paramsObject?.bankCode.split(' ')[1]
                    ? paramsObject?.bankCode
                    : paramsObject.bankCode + ' ' + paramsObject.regionCode}
                </h3>
              </span>
            </Row>
          )}
          {paramsObject.currency && (
            <Row
              style={{
                justifyContent: 'space-between',
                marginTop: -30,
              }}
            >
              <span className="p-2 pageheader">
                <h3 className="pagetitle">
                  Currency : {paramsObject.currency}
                </h3>
              </span>
            </Row>
          )}
          {paramsObject.region && (
            <Row
              style={{
                justifyContent: 'space-between',
                marginTop: -30,
              }}
            >
              <span className="p-2 pageheader">
                <h3 className="pagetitle">Country : {paramsObject.region}</h3>
              </span>
            </Row>
          )}
          {paramsObject.nameCode && (
            <Row
              style={{
                justifyContent: 'space-between',
                marginTop: -30,
              }}
            >
              <span className="p-2 pageheader">
                <h3 className="pagetitle">Name : {paramsObject.nameCode}</h3>
              </span>
            </Row>
          )}
          {paramsObject.type && (
            <Row
              style={{
                justifyContent: 'space-between',
                marginTop: -30,
              }}
            >
              <span className="p-2 pageheader">
                <h3 className="pagetitle">
                  Account Type : {paramsObject.type}
                </h3>
              </span>
            </Row>
          )}
        </Col>
      </Row>
      <PivotTableV2
        dataSource={responseData?.items}
        dataConfig={[...tableDataConfig, actionColumn]}
        onSearch={handleSearchColumn}
        onSortAsc={handleOnAscendingSorting}
        onSortDesc={handleOnDescendingSorting}
        onClearFilter={clearSearchFilters}
        actionOnClick={handleClearFilters}
        sticky
      />
      <Pagination
        totalItems={responseData?.totalItem}
        currentPage={pageIndex}
        onPageChange={handlePageChange}
      />
      <StyledDialog
        onClose={() => setOpenEditModal(false)}
        open={openEditModal}
        // maxWidth={'xl'}
        fullWidth
      >
        <DialogTitle>Edit </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>Payee/Remitter</span>
              <input
                name="payeeOrRemitter"
                value={formData.payeeOrRemitter}
                onChange={handleChange}
                className={styles.tableInput}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>Description</span>
              <input
                name="description"
                value={formData.description}
                onChange={handleChange}
                className={styles.tableInput}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>PV No.</span>
              <input
                name="pvNo"
                value={formData.pvNo}
                onChange={handleChange}
                className={styles.tableInput}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>Cheque No.</span>
              <input
                name="chequeNo"
                value={formData.chequeNo}
                onChange={handleChange}
                className={styles.tableInput}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <span
              onClick={() => setOpenEditModal(false)}
              className={styles.actionButton3}
            >
              Cancel
            </span>
            <button className={styles.actionButton3} type="submit">
              Submit
            </button>
          </DialogActions>
        </form>
      </StyledDialog>
      <StyledDialog open={showSuccess} onClose={() => setShowSuccess(false)}>
        <DialogTitle>Edit Success</DialogTitle>
        <DoneOutlineIcon sx={{ marginX: 'auto', fontSize: 40 }} />
        <Button
          onClick={() => setShowSuccess(false)}
          variant="outlined"
          sx={{
            my: 3,
            mx: 2,
            color: '#ffc107',
            ':hover': { bgcolor: 'none', borderColor: '#ffc107' },
            borderColor: '#ffc107',
          }}
        >
          Close
        </Button>
      </StyledDialog>
      {isLoading && (
        <Spinner
          style={{
            position: 'fixed',
            top: '20em',
            left: '59%',
            height: '70px',
            width: '70px',
          }}
          animation="border"
          variant="primary"
        />
      )}
    </Container>
  );
};

export default MultiLedgerTransactionRecords;
