import React, { useEffect, useState } from 'react';
import { Row, Container, Col, Card, Modal, NavLink } from 'react-bootstrap';
// import { numberWithCommas } from '../helpers/common';
import { AspectRatio, Close } from '@mui/icons-material';
import {
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  TextField,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const axios = require('axios');

const postAsync = async (url, payload, header = null) => {
  const base_url = 'https://apiv2-398476.backoffice.gg/api/v1';

  try {
    var headers = { 'Content-Type': 'application/json; charset=utf-8' };
    if (header) headers = Object.assign(headers, header);
    const token = localStorage.getItem('newApiToken');

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    var resp = await axios({
      headers: headers,
      method: 'POST',
      url: `${base_url}/${url}`,
      data: payload,
    });

    return resp.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const DashboardV2 = () => {
  const [dashboardData, setDashboardData] = useState({
    portfolios: [],
  });

  const [byClient, setByClient] = useState([]);
  const [byName, setByName] = useState([]);
  const [byCountry, setByCountry] = useState([]);
  const [byCountryAndBank, setByCountryAndBank] = useState([]);
  const [byCurrency, setByCurrency] = useState([]);
  const [byCurrencyAndType, setByCurrencyAndType] = useState([]);
  const [showZoom, setShowZoom] = useState(false);
  const [zoomContent, setZoomContent] = useState();
  const [filters, setFilters] = useState({
    portfolio: {
      PortfolioId: '',
      PortfolioName: '',
    },
    monthYear: new Date(),
    countries: {
      countryList: [],
      countrySelected: [],
    },
    bankAndCountry: {
      list: [],
      selected: [],
    },
    currencies: {
      list: [],
      selected: [],
    },
    currencyType: {
      list: [],
      selected: [],
    },
  });

  // filters
  const [widgetFilter, setWidgetFilter] = useState({
    client: [],
    clientOptions: [],
    country: [],
    countryOptions: [],
    countryBank: [],
    countryBankOptions: [],
    currency: [],
    currencyOptions: [],
    currencyType: [],
    currencyTypeOptions: [],
  });

  const renderDropDownList = (label, data, setData, key, optionKey, func) => {
    return (
      <Select
        //id="ddlByClient"
        multiple
        value={data[key]}
        onChange={(e, a) => {
          if (
            a.props.value == 'ALL' &&
            data[optionKey].includes(a.props.value)
          ) {
            data[optionKey] = [];
          } else if (
            a.props.value == 'ALL' &&
            !widgetFilter[optionKey].includes(a.props.value)
          ) {
            data[optionKey] = widgetFilter[key];
          } else {
            if (!data[optionKey].includes(a.props.value)) {
              data[optionKey] = [...[a.props.value].concat(data[optionKey])];
            } else {
              data[optionKey] = data[optionKey].filter(
                (x) => x != a.props.value
              );
            }
            if (
              data[optionKey].filter((x) => x != 'ALL').length ==
                data[key].filter((x) => x != 'ALL').length &&
              !data[optionKey].includes('ALL')
            )
              data[optionKey] = [...['ALL'].concat(data[optionKey])];
            else if (
              data[optionKey].filter((x) => x != 'ALL').length !=
                data[key].filter((x) => x != 'ALL').length &&
              data[optionKey].includes('ALL')
            )
              data[optionKey] = data[optionKey].filter((x) => x != 'ALL');
          }
          setData({ ...data });

          if (func) func();
        }}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => label}
        MenuProps={{
          PaperProps: {
            sx: {
              '& .MuiMenuItem-root:hover': {
                backgroundColor: 'lightgrey',
                color: 'black',
              },
              '& .MuiMenuItem-root.Mui-selected:hover': {
                backgroundColor: 'lightgrey',
                color: 'black',
              },
              '& .MuiCheckbox-root': {
                color: 'white',
              },
              '& .MuiList-root': {
                background: 'rgb(31, 33, 37) !important',
                color: 'white !important',
                width: '150px',
              },
            },
          },
        }}
        sx={{
          width: '150px',
          height: '27px',
          border: '1px solid #d9d9d9 !important',
          '.MuiButtonBase-root': {
            color: 'white',
          },
        }}
        style={{
          borderRadius: '4px',
          width: '200px !important',
          height: '32px !important',
          fontSize: '14px',
          left: '0px',
        }}
      >
        {data[key].map((name, idx) => (
          <MenuItem key={idx} value={name}>
            <Checkbox checked={data[optionKey].indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    );
  };

  const renderDropDownListV2 = (label, data, objName, key, optionKey, func) => {
    return (
      <Select
        //id="ddlByClient"
        multiple
        value={data[objName][key]}
        onChange={(e, a) => {
          if (
            a.props.value == 'ALL' &&
            data[objName][optionKey].includes(a.props.value)
          ) {
            data[objName][optionKey] = [];
          } else if (
            a.props.value == 'ALL' &&
            !data[objName][optionKey].includes(a.props.value)
          ) {
            data[objName][optionKey] = data[objName][key];
          } else {
            if (!data[objName][optionKey].includes(a.props.value)) {
              data[objName][optionKey] = [
                ...[a.props.value].concat(data[objName][optionKey]),
              ];
            } else {
              data[objName][optionKey] = data[objName][optionKey].filter(
                (x) => x != a.props.value
              );
            }
            if (
              data[objName][optionKey].filter((x) => x != 'ALL').length ==
                data[objName][key].filter((x) => x != 'ALL').length &&
              !data[objName][optionKey].includes('ALL')
            )
              data[objName][optionKey] = [
                ...['ALL'].concat(data[objName][optionKey]),
              ];
            else if (
              data[objName][optionKey].filter((x) => x != 'ALL').length !=
                data[objName][key].filter((x) => x != 'ALL').length &&
              data[objName][optionKey].includes('ALL')
            )
              data[objName][optionKey] = data[objName][optionKey].filter(
                (x) => x != 'ALL'
              );
          }

          setFilters({ ...data });

          if (func) func();
        }}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => label}
        MenuProps={{
          PaperProps: {
            sx: {
              '& .MuiMenuItem-root:hover': {
                backgroundColor: 'lightgrey',
                color: 'black',
              },
              '& .MuiMenuItem-root.Mui-selected:hover': {
                backgroundColor: 'lightgrey',
                color: 'black',
              },
              '& .MuiCheckbox-root': {
                color: 'white',
              },
              '& .MuiList-root': {
                background: 'rgb(31, 33, 37) !important',
                color: 'white !important',
                width: '150px',
              },
            },
          },
        }}
        sx={{
          width: '150px',
          height: '27px',
          border: '1px solid #d9d9d9 !important',
          '.MuiButtonBase-root': {
            color: 'white',
          },
        }}
        style={{
          borderRadius: '4px',
          width: '200px !important',
          height: '32px !important',
          fontSize: '14px',
          left: '0px',
        }}
      >
        {data[objName][key].map((row) => (
          <MenuItem key={row} value={row}>
            <Checkbox checked={data[objName][optionKey].indexOf(row) > -1} />
            <ListItemText primary={row} />
          </MenuItem>
        ))}
      </Select>
    );
  };

  const renderSingleDropDownList = (
    label,
    list,
    textKey,
    valueKey,
    filter,
    setFilter,
    filterKey,
    func
  ) => {
    return (
      <Select
        value={filter[filterKey][valueKey]}
        onChange={(e, a) => {
          filter[filterKey] = list.filter(
            (row) => row[valueKey] == a.props.value
          )[0];
          setFilter({ ...filter });
          if (func) func();
        }}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => filter[filterKey][textKey]}
        MenuProps={{
          PaperProps: {
            sx: {
              '& .MuiMenuItem-root:hover': {
                backgroundColor: 'lightgrey',
                color: 'black',
              },
              '& .MuiMenuItem-root.Mui-selected:hover': {
                backgroundColor: 'lightgrey',
                color: 'black',
              },
              '& .MuiCheckbox-root': {
                color: 'white',
              },
              '& .MuiList-root': {
                background: 'rgb(31, 33, 37) !important',
                color: 'white !important',
                width: '250px',
              },
              '& .MuiListItemText-primary': {
                fontSize: '13px !important',
              },
            },
          },
        }}
        sx={{
          width: '250px',
          height: '27px',
          border: '1px solid #d9d9d9 !important',
          '.MuiButtonBase-root': {
            color: 'white',
          },
        }}
        style={{
          borderRadius: '4px',
          width: '200px !important',
          height: '32px !important',
          fontSize: '14px',
          left: '0px',
        }}
      >
        {list.map((row, idx) => (
          <MenuItem key={idx} value={row[valueKey]}>
            <ListItemText primary={row[textKey]} />
          </MenuItem>
        ))}
      </Select>
    );
  };

  const buildCardButton = (isZoom, evnt) => {
    return (
      <span className="card-zoom">
        <NavLink
          title="Expand"
          onClick={() => {
            setZoomContent(evnt());
            setShowZoom(true);
          }}
          style={{ display: isZoom ? 'none' : '' }}
        >
          <AspectRatio />
        </NavLink>
        <NavLink
          title="Close"
          onClick={() => {
            setShowZoom(false);
          }}
          style={{ display: isZoom ? '' : 'none' }}
        >
          <Close />
        </NavLink>
      </span>
    );
  };

  const getCardBodyStyle = (isZoom = false) => {
    return {
      height: isZoom ? 'auto' : '250px',
      overflowY: !isZoom ? 'auto' : 'unset',
    };
  };

  const generateCard = (title, contents, balance, evnt, isZoom = false) => {
    return (
      <Card style={{ marginBottom: isZoom ? '0px' : '30px' }}>
        <Card.Header>
          {title}
          {buildCardButton(isZoom, evnt)}
        </Card.Header>
        <Card.Body style={getCardBodyStyle(isZoom)}>{contents()}</Card.Body>
        <Card.Footer>
          <div className="dashboard-widget-footer">
            <span>Grand Total</span>
            {balance}
          </div>
        </Card.Footer>
      </Card>
    );
  };

  const buildByClient = () => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Client</th>
            <th style={{ width: '75px' }}>
              Movement
              <br />
              (USD)
            </th>
            <th style={{ width: '75px' }}>
              Previous
              <br />
              Balance (USD)
            </th>
            <th style={{ width: '75px' }}>
              Current
              <br />
              Balance (USD)
            </th>
          </tr>
        </thead>
        <tbody>
          {byClient == null || byClient.length == 0 ? (
            <tr>
              <td colSpan="4" style={{ textAlign: 'center' }}>
                No record
              </td>
            </tr>
          ) : (
            byClient.items
              .sort((a, b) => {
                if (a < b) return -1;
                if (a > b) return 1;
                return 0;
              })
              .map((x, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td
                        style={{
                          fontWeight: 'bold',
                          // color: 'rgb(255, 193, 7)',
                        }}
                      >
                        {x.code ?? '-'}
                      </td>

                      <td
                        style={{
                          width: '100px',
                          textAlign: 'right',
                          fontWeight: 'bold',
                          color:
                            x.movement > 0
                              ? 'green'
                              : x.movement < 0
                              ? 'red'
                              : 'white',
                        }}
                      >
                        {x.movement ?? '-'}
                      </td>

                      <td
                        style={{
                          width: '75px',
                          textAlign: 'right',
                          fontWeight: 'bold',
                          // color: 'rgb(255, 193, 7)',
                        }}
                      >
                        {x.prevBalanceUSD > 0 ? x.prevBalanceUSD : '-'}
                      </td>
                      <td
                        style={{
                          width: '75px',
                          textAlign: 'right',
                          fontWeight: 'bold',
                          // color: 'rgb(255, 193, 7)',
                        }}
                      >
                        {x.currentBalanceUSD > 0 ? x.currentBalanceUSD : '-'}
                      </td>
                    </tr>
                  </>
                );
              })
          )}
        </tbody>
      </table>
    );
  };
  const buildByName = () => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th style={{ width: '75px' }}>
              Movement
              <br />
              (USD)
            </th>
            <th style={{ width: '75px' }}>
              Previous
              <br />
              Balance (USD)
            </th>
            <th style={{ width: '75px' }}>
              Current
              <br />
              Balance (USD)
            </th>
          </tr>
        </thead>
        <tbody>
          {byName == null || byName.length == 0 ? (
            <tr>
              <td colSpan="4" style={{ textAlign: 'center' }}>
                No record
              </td>
            </tr>
          ) : (
            byName.items
              .sort((a, b) => {
                if (a < b) return -1;
                if (a > b) return 1;
                return 0;
              })
              .map((x, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td
                        style={{
                          fontWeight: 'bold',
                          // color: 'rgb(255, 193, 7)',
                        }}
                      >
                        {x.code ?? '-'}
                      </td>

                      <td
                        style={{
                          width: '100px',
                          textAlign: 'right',
                          fontWeight: 'bold',
                          color:
                            x.movement > 0
                              ? 'green'
                              : x.movement < 0
                              ? 'red'
                              : 'white',
                        }}
                      >
                        {x.movement ?? '-'}
                      </td>
                      <td
                        style={{
                          width: '75px',
                          textAlign: 'right',
                          fontWeight: 'bold',
                          // color: 'rgb(255, 193, 7)',
                        }}
                      >
                        {x.prevBalanceUSD > 0 ? x.prevBalanceUSD : '-'}
                      </td>
                      <td
                        style={{
                          width: '75px',
                          textAlign: 'right',
                          fontWeight: 'bold',
                          // color: 'rgb(255, 193, 7)',
                        }}
                      >
                        {x.currentBalanceUSD > 0 ? x.currentBalanceUSD : '-'}
                      </td>
                    </tr>
                  </>
                );
              })
          )}
        </tbody>
      </table>
    );
  };

  const buildByCountry = () => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>
              {/* {renderDropDownListV2(
                'Country',
                filters,
                'countries',
                'countryList',
                'countrySelected',
                noop
                // () =>
                //   setByCountry(
                //     byCountry.filter((x) =>
                //       filters.countries.countrySelected.includes(x.region)
                //     )
                //   )
              )} */}
              Country
            </th>

            <th style={{ width: '75px' }}>
              Movement
              <br />
              (USD)
            </th>
            <th style={{ width: '75px' }}>
              Previous
              <br />
              Balance (USD)
            </th>
            <th style={{ width: '75px' }}>
              Current
              <br />
              Balance (USD)
            </th>
          </tr>
        </thead>
        <tbody>
          {byCountry == null || byCountry.length == 0 ? (
            <tr>
              <td colSpan="4" style={{ textAlign: 'center' }}>
                No record
              </td>
            </tr>
          ) : (
            byCountry.items
              .filter((country) =>
                filters.countries.countrySelected.includes(country.region)
              )
              .sort((a, b) => {
                if (a.region < b.region) return -1;
                if (a.region > b.region) return 1;
                return 0;
              })
              .map((x) => {
                return (
                  <tr key={x.Id}>
                    <td style={{ fontWeight: 'bold' }}>{x.region}</td>
                    <td
                      style={{
                        width: '75px',
                        textAlign: 'right',
                        color:
                          x.movement > 0
                            ? 'green'
                            : x.movement < 0
                            ? 'red'
                            : 'white',
                        fontWeight: 'bold',
                      }}
                    >
                      {x.movement ?? '-'}
                    </td>
                    <td
                      style={{
                        width: '150px',
                        textAlign: 'right',
                        fontWeight: 'bold',
                      }}
                    >
                      {x.prevBalanceUSD > 0 ? x.prevBalanceUSD : '-'}
                    </td>
                    <td
                      style={{
                        width: '150px',
                        textAlign: 'right',
                        fontWeight: 'bold',
                      }}
                    >
                      {x.currentBalanceUSD > 0 ? x.currentBalanceUSD : '-'}
                    </td>
                  </tr>
                );
              })
          )}
        </tbody>
      </table>
    );
  };

  const buildByCountryAndBank = () => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Bank</th>

            <th style={{ width: '75px' }}>
              Movement
              <br />
              (USD)
            </th>
            <th style={{ width: '75px' }}>
              Previous
              <br />
              Balance (USD)
            </th>
            <th style={{ width: '75px' }}>
              Current
              <br />
              Balance (USD)
            </th>
          </tr>
        </thead>
        <tbody>
          {byCountryAndBank == null || byCountryAndBank.length == 0 ? (
            <tr>
              <td colSpan="4" style={{ textAlign: 'center' }}>
                No record
              </td>
            </tr>
          ) : (
            byCountryAndBank.items
              .sort((a, b) => {
                if (a.bankCode < b.bankCode) return -1;
                if (a.bankCode > b.bankCode) return 1;
                return 0;
              })
              .map((x, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td
                        style={{
                          fontWeight: 'bold',
                          // color: 'rgb(255, 193, 7)',
                        }}
                      >
                        {x.bankCode} {x.region}
                      </td>
                      <td
                        style={{
                          width: '100px',
                          textAlign: 'right',
                          fontWeight: 'bold',
                          color:
                            x.movement > 0
                              ? 'green'
                              : x.movement < 0
                              ? 'red'
                              : 'white',
                        }}
                      >
                        {x.movement ?? '-'}
                      </td>
                      <td
                        style={{
                          width: '75px',
                          textAlign: 'right',
                          fontWeight: 'bold',
                          // color: 'rgb(255, 193, 7)',
                        }}
                      >
                        {x.prevBalanceUSD > 0 ? x.prevBalanceUSD : '-'}
                      </td>
                      <td
                        style={{
                          width: '75px',
                          textAlign: 'right',
                          fontWeight: 'bold',
                          // color: 'rgb(255, 193, 7)',
                        }}
                      >
                        {x.currentBalanceUSD > 0 ? x.currentBalanceUSD : '-'}
                      </td>
                    </tr>
                  </>
                );
              })
          )}
        </tbody>
      </table>
    );
  };

  const buildByCurrency = () => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Currency</th>
            <th style={{ width: '75px' }}>
              Movement
              <br />
              (USD)
            </th>
            <th style={{ width: '75px' }}>
              Previous
              <br />
              Balance (USD)
            </th>
            <th style={{ width: '75px' }}>
              Current
              <br />
              Balance (USD)
            </th>
          </tr>
        </thead>
        <tbody>
          {byCurrency == null || byCurrency.length == 0 ? (
            <tr>
              <td colSpan="4" style={{ textAlign: 'center' }}>
                No record
              </td>
            </tr>
          ) : (
            byCurrency.items
              .sort((a, b) => {
                if (a.currency < b.currency) return -1;
                if (a.currency > b.currency) return 1;
                return 0;
              })
              .map((x) => {
                return (
                  <tr key={x.portfolioId}>
                    <td style={{ fontWeight: 'bold' }}>{x.currency}</td>
                    <td
                      style={{
                        width: '100px',
                        textAlign: 'right',
                        color:
                          x.movement > 0
                            ? 'green'
                            : x.movement < 0
                            ? 'red'
                            : 'white',
                        fontWeight: 'bold',
                      }}
                    >
                      {x.movement ?? '-'}
                    </td>
                    <td
                      style={{
                        width: '75px',
                        textAlign: 'right',
                        fontWeight: 'bold',
                        color: x.prevBalanceUSD < 0 ? 'red' : 'white',
                      }}
                    >
                      {(x.prevBalanceUSD ?? 0) === 0 ? '-' : x.prevBalanceUSD}
                    </td>
                    <td
                      style={{
                        width: '75px',
                        textAlign: 'right',
                        fontWeight: 'bold',
                        color: x.currentBalanceUSD < 0 ? 'red' : 'white',
                      }}
                    >
                      {(x.currentBalanceUSD ?? 0) === 0
                        ? '-'
                        : x.currentBalanceUSD}
                    </td>
                  </tr>
                );
              })
          )}
        </tbody>
      </table>
    );
  };

  const buildByCurrencyAndType = () => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Account Type</th>

            <th style={{ width: '75px' }}>
              Movement
              <br />
              (USD)
            </th>
            <th style={{ width: '75px' }}>
              Previous
              <br />
              Balance (USD)
            </th>
            <th style={{ width: '75px' }}>
              Current
              <br />
              Balance (USD)
            </th>
          </tr>
        </thead>
        <tbody>
          {byCurrencyAndType == null || byCurrencyAndType.length == 0 ? (
            <tr>
              <td colSpan="4" style={{ textAlign: 'center' }}>
                No record
              </td>
            </tr>
          ) : (
            byCurrencyAndType.items
              .sort((a, b) => {
                if (a < b) return -1;
                if (a > b) return 1;
                return 0;
              })
              .map((x, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td
                        style={{
                          fontWeight: 'bold',
                          // color: 'rgb(255, 193, 7)',
                        }}
                      >
                        {x.type}
                      </td>
                      <td
                        style={{
                          width: '100px',
                          textAlign: 'right',
                          fontWeight: 'bold',
                          color: x.movement ? 'green' : 'red',
                        }}
                      >
                        {x.movement ?? '-'}
                      </td>
                      <td
                        style={{
                          width: '75px',
                          textAlign: 'right',
                          fontWeight: 'bold',
                          // color: 'rgb(255, 193, 7)',
                        }}
                      >
                        {x.prevBalanceUSD > 0 ? x.prevBalanceUSD : '-'}
                      </td>
                      <td
                        style={{
                          width: '75px',
                          textAlign: 'right',
                          fontWeight: 'bold',
                          // color: 'rgb(255, 193, 7)',
                        }}
                      >
                        {x.currentBalanceUSD > 0 ? x.currentBalanceUSD : '-'}
                      </td>
                    </tr>
                  </>
                );
              })
          )}
        </tbody>
      </table>
    );
  };

  const bindStates = (results, pf) => {
    if (results.byClient == null) return null;
    setDashboardData(results);
    setByClient(results.byClient);
    // setByClient([
    //   ...new Set(
    //     results.byClient.items.filter(
    //       (row) =>
    //         row.portfolioId == pf.portfolio_id &&
    //         widgetFilter.clientOptions.includes(row.category)
    //     )
    //   ),
    // ]);

    setByName(results.byName);

    /* Country */
    setByCountry(results.byCountry);
    var country = results.byCountry.items
      .filter((row) => row.portfolioId == pf.portfolio_id)
      .map((row) => row.region);
    country = country.sort();
    country = ['ALL'].concat(country);
    filters.countries.countryList = country;
    filters.countries.countrySelected = country;

    /* Bank And Country */
    filters.bankAndCountry.list = country;
    filters.bankAndCountry.selected = country;
    setByCountryAndBank(results.byCountryBank);

    /* Currency */
    var currency = [
      ...new Set(
        results.byCurrency.items
          .filter((row) => row.portfolioId == pf.portfolio_id)
          .map((row) => row.currency)
      ),
    ];
    currency = currency.sort();
    currency = ['ALL'].concat(currency);
    filters.currencies.list = currency;
    filters.currencies.selected = currency;
    setByCurrency(results.byCurrency);

    /* Currency And Type */
    filters.currencyType.list = currency;
    filters.currencyType.selected = currency;
    setByCurrencyAndType(results.byType);

    setFilters({ ...filters });
  };

  const buildWidgetPattern = (results, portfolioId) => {
    if (results.byClient == null) return;
    var client = [
      ...new Set(
        results.byClient.items
          .filter((row) => row.portfolioId == portfolioId)
          .map((row) => row.Category)
      ),
    ];
    client = ['ALL'].concat(client);

    var country = [
      ...new Set(
        results.byCountry.items
          .filter((row) => row.PortfolioId == portfolioId)
          .map((row) => row.Region)
      ),
    ];

    country = ['ALL'].concat(country);
    var currency = [
      ...new Set(
        results.byCurrency.items
          .filter((row) => row.PortfolioId == portfolioId)
          .map((row) => row.Currency)
      ),
    ];

    currency = ['ALL'].concat(currency);
    setWidgetFilter({
      client: client,
      clientOptions: client,
      country: country,
      countryOptions: country,
      countryBank: country,
      countryBankOptions: country,
      currency: currency,
      currencyOptions: currency,
      currencyType: currency,
      currencyTypeOptions: currency,
    });
  };

  const setClientByDataSet = (results) => {
    setByClient(results.byClient);
  };

  useEffect(() => {
    const init = async () => {
      var results = await postAsync('dashboard/treasury', {
        date: filters.monthYear,
      });

      var pf =
        results.result.portfolios.length > 0
          ? results.result.portfolios[0]
          : { PortfolioId: '', PortfolioName: '' };
      filters.portfolio = pf;
      setFilters({ ...filters });
      buildWidgetPattern(results.result, pf.portfolio_id);
      bindStates(results.result, pf);
    };
    init();
  }, []);

  useEffect(() => {
    (async () => {
      var results = await postAsync('dashboard/treasury', {
        date: filters.monthYear,
      });
      buildWidgetPattern(results.result, filters.portfolio.PortfolioId);
      bindStates(results.result, filters.portfolio);
      setClientByDataSet(results.result);
    })();
  }, [filters.portfolio]);

  useEffect(() => {
    (async () => {
      var results = await postAsync('dashboard/treasury', {
        date: filters.monthYear,
      });
      buildWidgetPattern(results.result, filters.portfolio.portfolio_id);
      bindStates(results.result, filters.portfolio);
      setClientByDataSet(results.result);
    })();
  }, [filters.monthYear, filters.portfolio]);

  useEffect(() => {
    setZoomContent(
      generateCard('By Name', buildByName, byName.grandTotal, buildByName, true)
    );
  }, [byName]);

  useEffect(() => {
    setZoomContent(
      generateCard(
        'By Client',
        buildByClient,
        byClient.grandTotal,
        buildByClient,
        true
      )
    );
  }, [byClient]);

  useEffect(() => {
    setZoomContent(
      generateCard(
        'By Country',
        buildByCountry,
        byCountry.grandTotal,
        buildByCountry,
        true
      )
    );
  }, [byCountry]);

  useEffect(() => {
    setZoomContent(
      generateCard(
        'By Bank',
        buildByCountryAndBank,
        byCountryAndBank.grandTotal,
        buildByCountryAndBank,
        true
      )
    );
  }, [byCountryAndBank]);

  useEffect(() => {
    setZoomContent(
      generateCard(
        'By Currency',
        buildByCurrency,
        byCurrency.grandTotal,
        buildByCurrency,
        true
      )
    );
  }, [byCurrency]);

  useEffect(() => {
    setZoomContent(
      generateCard(
        'By Currency And Type',
        buildByCurrencyAndType,
        byCurrencyAndType.grandTotal,
        buildByCurrencyAndType,
        true
      )
    );
  }, [byCurrencyAndType]);

  /* Filtering */
  useEffect(() => {
    if (dashboardData.byCountry == null) return;
    setByCountry(dashboardData.byCountry);
  }, [filters.countries.countrySelected]);

  useEffect(() => {
    if (dashboardData.byCountryBank == null) return;
    setByCountryAndBank(dashboardData.byCountryBank);
  }, [filters.bankAndCountry.selected]);

  useEffect(() => {
    if (dashboardData.byCurrency == null) return;
    setByCurrency(dashboardData.byCurrency);
  }, [filters.currencies.selected]);

  useEffect(() => {
    if (dashboardData.byType == null) return;
    // setByCurrencyAndType(
    //   dashboardData.byType.items.filter((row) =>
    //     filters.currencyType.selected.includes(row.currency)
    //   )
    // );
  }, [filters.currencyType.selected]);
  /* End Filtering */

  return (
    <Container fluid>
      <Modal
        className="dashboard-modal"
        show={showZoom}
        size="lg"
        top
        aria-labelledby="zoom-header"
        onHide={() => setShowZoom(false)}
      >
        <Modal.Body>
          <Row className="pms-dashboard">
            <Col lg={12}>{zoomContent}</Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Row>
        <Col lg={12}>
          <Row style={{ marginBottom: '8px' }}>
            <span className="p-2 pageheader">
              <h3 className="pagetitle">Treasury Dashboard</h3>
            </span>
          </Row>
        </Col>
      </Row>
      <Row className="pms-dashboard" style={{ marginTop: '30px' }}>
        <Col lg={12} style={{ marginBottom: '20px' }}>
          <span style={{ color: 'white' }}>Filter by: </span>
          {/* {renderSingleDropDownList(
            'Portfolio',
            dashboardData.portfolios,
            'portfolio_name',
            'portfolio_id',
            filters,
            setFilters,
            'portfolio',
            () => {}
          )} */}
          {/* &nbsp;&nbsp; */}
          {/* {renderDropDownList(
            'Client',
            widgetFilter,
            setWidgetFilter,
            'client',
            'clientOptions',
            async () => {
              var results = await postAsync('dashboard/treasury', {
                date: filters.monthYear,
                boCode: JSON.stringify(widgetFilter.clientOptions),
              });
              bindStates(results.result, filters.portfolio);
              setByClient(
                dashboardData.byClient.items.filter((x) =>
                  widgetFilter.clientOptions.includes(x.Category)
                )
              );
              setByName(dashboardData.byName.items);
            }
          )} */}
          &nbsp;&nbsp;
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              // label={'Month'}
              views={['month', 'year']}
              renderInput={(props) => (
                <TextField {...props} size="small" helperText={null} />
              )}
              onChange={(newValue) => {
                filters.monthYear = newValue;
                setFilters({ ...filters });
              }}
              InputProps={{
                sx: {
                  '&.MuiOutlinedInput-root': {
                    border: '1px solid #fff',
                    height: '27px',
                  },
                  '& .MuiInputLabel-outlined': {
                    border: '1px solid #FF0000 !important',
                  },
                },
              }}
              value={filters.monthYear}
              openTo="month"
            />
          </LocalizationProvider>
        </Col>
        <Col lg={6}>
          {generateCard('By Name', buildByName, byName.grandTotal, () =>
            generateCard(
              'By Name',
              buildByName,
              byName.grandTotal,
              buildByName,
              true
            )
          )}
        </Col>

        <Col lg={6}>
          {generateCard('By Client', buildByClient, byClient.grandTotal, () =>
            generateCard(
              'By Client',
              buildByClient,
              byClient.grandTotal,
              buildByClient,
              true
            )
          )}
        </Col>

        <Col lg={6}>
          {generateCard(
            'By Country',
            buildByCountry,
            byCountry.grandTotal,
            () =>
              generateCard(
                'By Country',
                buildByCountry,
                byCountry.grandTotal,
                buildByCountry,
                true
              )
          )}
        </Col>

        <Col lg={6}>
          {generateCard(
            'By Bank',
            buildByCountryAndBank,
            byCountryAndBank.grandTotal,
            () =>
              generateCard(
                'By Bank',
                buildByCountryAndBank,
                byCountryAndBank.grandTotal,
                buildByCountryAndBank,
                true
              )
          )}
        </Col>

        <Col lg={6}>
          {generateCard(
            'By Currency',
            buildByCurrency,
            byCurrency.grandTotal,
            () =>
              generateCard(
                'By Currency',
                buildByCurrency,
                byCurrency.grandTotal,
                buildByCurrency,
                true
              )
          )}
        </Col>

        <Col lg={6}>
          {generateCard(
            'By Account Type',
            buildByCurrencyAndType,
            byCurrencyAndType.grandTotal,
            () =>
              generateCard(
                'By Account Type',
                buildByCurrencyAndType,
                byCurrencyAndType.grandTotal,
                buildByCurrencyAndType,
                true
              )
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardV2;
